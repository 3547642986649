<div class="carousel-wrap-container">
    <div [hidden]="!(slides?.length && slides.length > 1)" class="swiper-button-prev swiper-button-prev-hero">
        <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z" fill="currentColor" transform-origin="center" transform="rotate(180)"></path></svg>
    </div>

    <swiper-container
        [hidden]="!slides?.length || !configuration.display"
        class="swiper-container gallery-images"
        init="false"
        #slider>
        <swiper-slide *ngFor="let slide of slides; let i = index">
            <img [src]="slide.image_src" [alt]="slide.image_alt" width="100%">

            <div class="hero-title-container">
                <h2 class="hero-heading"
                    *ngIf="slide.headline_content"
                    [innerHTML]="slide.headline_content">
                </h2>
                <p class="hero-body"
                    *ngIf="slide.subtext_content"
                    [innerHTML]="slide.subtext_content">
                </p>
                <div>
                    <button class="hero-button"
                            *ngIf="slide.button_link?.type === 'a-ng'"
                            [routerLink]="slide.button_link?.link"
                            [queryParams]="slide.button_link?.params ? slide.button_link.params : {}"
                            [innerHTML]="slide.button_content">
                    </button>
                    <a class="hero-button anchor-link"
                        *ngIf="slide.button_link?.type === 'a'"
                        [href]="slide.button_link?.link"
                        [innerHTML]="slide.button_content">
                    </a>
                    <button class="hero-button"
                            *ngIf="slide.button_link?.type === 'page-jump'"
                            (click)="onPageJump(slide.button_link?.link)"
                            [innerHTML]="slide.button_content">
                        </button>
                </div>
            </div>
        </swiper-slide>
        <div slot="container-end">
            <div class="pagination" *ngIf="sliderConfiguration.pagination && slides?.length && slides.length > 1"></div>
        </div>
    </swiper-container>

    <div [hidden]="!(slides?.length && slides.length > 1)" class="swiper-button-next swiper-button-next-hero">
        <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z" fill="currentColor"></path></svg>
    </div>
</div>

<div *ngIf="!slides?.length && configuration.display" class="is-loading">
    <div class="inner"></div>
</div>

