<div
    [hidden]="!configuration.display"
    class="ras-items-carousel-container"
    [attr.id]="configuration.id"
    [ngClass]="configuration.cssClass">
    <div class="ras-items-carousel-header" *ngIf="configuration.header">
        <p class="is-font-h1 is-font-bold" *ngIf="configuration.header.title" [innerHtml]="configuration.header.title"></p>
        <a *ngIf="configuration.header.link"
           [routerLink]="configuration.header.link.url"
           [queryParams]="configuration.header.link.params"
           [innerHtml]="configuration.header.link.label"></a>
    </div>

    <div class="swiper-button-prev swiper-button-prev-items">
        <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z" fill="currentColor" transform-origin="center" transform="rotate(180)"></path></svg>
    </div>
    <swiper-container class="swiper-container" init="false" #slider [hidden]="!items?.length">
        <!-- From API -->
        <ng-container *ngIf="configuration.dataGroup; else staticList">
            <swiper-slide *ngFor="let item of items"
                (click)="onUnsetNavigation()"
                [routerLink]="getLink( item )"
                class="swiper-slide"
                [queryParams]="getParams( item )"
                rel="follow"
                displayType="search-result-grid"
                lazy="true"
                >
                <div
                    class="is-rounded ras-item-carousel"
                    matRipple
                    ras-list-item
                    [isLoading]="isLoading"
                    [data]="item"
                    [configuration]="configuration.itemsConfiguration">
                </div>
            </swiper-slide>
        </ng-container>

        <!-- From static list of items -->
        <ng-template #staticList>
            <swiper-slide *ngFor="let item of configuration.items"
                class="swiper-slide"
                rel="follow"
                (click)="onUnsetNavigation()"
                [routerLink]="item.link?.url"
                [queryParams]="item.link?.params"
                lazy="true"
                >
                <div
                    [content]="item.content"
                    ras-render-element>
                </div>
            </swiper-slide>
        </ng-template>

        <!-- Additional Item -->
        <swiper-slide *ngIf="configuration.addLastItem"
            class="swiper-slide"
            (click)="onUnsetNavigation()"
            [ngClass]="configuration.addLastItem.css_class"
            [routerLink]="configuration.addLastItem.link?.url"
            [queryParams]="configuration.addLastItem.link?.params"
            >
            <div
                class="is-rounded ras-item-carousel"
                matRipple
                ras-render-element
                [content]="configuration.addLastItem.content">
                >

            </div>
        </swiper-slide>
        <div slot="container-end">
            <div class="swiper-paginator" *ngIf="sliderConfiguration.pagination"></div>
        </div>
    </swiper-container>

    <div class="swiper-button-next swiper-button-next-items">
        <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.38296 20.0762C0.111788 19.805 0.111788 19.3654 0.38296 19.0942L9.19758 10.2796L0.38296 1.46497C0.111788 1.19379 0.111788 0.754138 0.38296 0.482966C0.654131 0.211794 1.09379 0.211794 1.36496 0.482966L10.4341 9.55214C10.8359 9.9539 10.8359 10.6053 10.4341 11.007L1.36496 20.0762C1.09379 20.3474 0.654131 20.3474 0.38296 20.0762Z" fill="currentColor"></path></svg>
    </div>
</div>
