import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, Input, OnInit } from '@angular/core'

// Services
import { UtilityService } from 'src/app/services/utility.service'

// Interfaces
import { IBrandSectionConfiguration } from 'src/app/interfaces/components/component-brand-section.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const BrandSectionComponentDefaults: IBrandSectionConfiguration = {
    type: 'brand_section',
    display: true,
    title: 'Browse Equipment by Brand',
    titleStyles: {},
    content: [],
}

export const BrandSectionComponentDevOpts: IComponentDevOptions = {
    config: {
        ...BrandSectionComponentDefaults,
        content: [{
            image_url: 'https://dummyimage.com/200x90/000000/fff',
            link: {
                params: {
                    'equipment.filter_make': 'BrandValue'
                },
                type: 'a-ng',
                url: '/equipment/search'
            },
            title: 'Brand title'
        }, {
            image_url: 'https://catalog-assets.rousesales.com/_default/brand-logos/JLG.svg',
            title: 'JLG',
            link: {
                type: 'a-ng',
                url: '/equipment/search',
                params: {
                    'equipment.filter_make': 'JLG'
                }
            }
        },
        {
            image_url: 'https://catalog-assets.rousesales.com/_default/brand-logos/Genie.svg',
            title: 'Genie',
            link: {
                type: 'a-ng',
                url: '/equipment/search',
                params: {
                    'equipment.filter_make': 'Genie'
                }
            }
        }],
        titleStyles: {
            'font-size': '1.6rem',
            'line-height': '2.1rem',
            'text-align': 'left'
        }
    }
}

@Component({
    selector: 'ras-brand-section',
    templateUrl: './brand-section.component.html',
    styleUrls: ['./brand-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandSectionComponent implements OnInit, DoCheck {
    @Input() configuration: IBrandSectionConfiguration
    public readonly isIE: boolean = UtilityService.isIE()

    constructor( private ref: ChangeDetectorRef ) {}

    ngOnInit() {
        UtilityService.populateConfigDefaults(this.configuration, BrandSectionComponentDefaults)
        this.ref.detectChanges()
    }

    ngDoCheck(): void {
        this.ref.markForCheck()
    }
}
