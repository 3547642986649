import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

// Services
import { SearchService } from './../../../services/search.service'
import { UtilityService } from 'src/app/services/utility.service'
import { QueryService } from 'src/app/services/query.service'

// Interfaces
import { IBaseComponent } from 'src/app/interfaces/components/component-base.interface'
import { IBreadcrumbConfiguration, IBreadcrumbLinks } from 'src/app/interfaces/components/component-breadcrumb.interface'
import { IComponentDevOptions } from 'src/app/modules/dev/dev.page'

export const BreadcrumbComponentDefaults: IBreadcrumbConfiguration = {
    type: 'breadcrumb',
    display: true,
    links: [],
    isLoading: false,
}

export const BreadcrumbComponentDevOpts: IComponentDevOptions = {
    config: {
        ...BreadcrumbComponentDefaults,
        links: [{
            link: {
              path: '/home'
            },
            label: 'Home Crumb'
        }, {
            link: {
              path: '/equipment/search'
            },
            label: 'Label {{param1}} {{param2}} {{param3}}'
        }, {
            label: 'Current Page'
        }],
    },
    data: {
        param1: 'Based',
        param2: 'On',
        param3: 'Data',
    }
}

@Component({
    selector: 'ras-breadcrumb',
    styleUrls: ['./breadcrumb.component.scss'],
    templateUrl: 'breadcrumb.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit, OnDestroy, DoCheck, IBaseComponent {
    private $destroy = new Subject<void>()

    @Input() configuration: IBreadcrumbConfiguration
    @Input() data: any

    constructor(
        private searchService: SearchService,
        private ref: ChangeDetectorRef,
        private queryService: QueryService,
    ) { }

    ngOnInit(): void {
        UtilityService.populateConfigDefaults(this.configuration, BreadcrumbComponentDefaults)

        if (this.configuration?.dataGroup) {
            this.searchService.isLoading(
                this.configuration.dataGroup,
            ).pipe(
                takeUntil(this.$destroy),
            ).subscribe(isLoading => {
                this.configuration.isLoading = isLoading
                this.ref.markForCheck()
            })

            this.searchService.requestData(
                this.configuration.dataGroup,
            ).pipe(
                takeUntil(this.$destroy),
            ).subscribe(data => {
                if (data) {
                    this.data = this.isDataArray(data) ? { ...data.data[0] } : { ...data }
                    this.ref.markForCheck()
                }
            })
        } else {
            this.data = {}
            this.ref.markForCheck()
        }
    }

    ngDoCheck(): void {
        this.ref.markForCheck()
    }

    ngOnDestroy(): void {
        this.$destroy.next()
        this.$destroy.complete()
    }

    getParams(params: any) {
        if (!this.data || !params) {
            return
        }

        const queryParams = {}
        Object.keys(params).forEach(key => {
            queryParams[key] = this.data[params[key]]
        })

        return queryParams
    }


    replaceTemplate(template: string) {
        if (!this.data || !template) {
            return
        }

        // Check if template needs data from service Ex: queryService.queryParamKey
        if (/({{)(.*)(\.)(.*)(}})/.test(template)) {
            const [, , service, , key, ,] = template.split(/({{)(.*)(\.)(.*)(}})/)
            switch (service) {
                case 'queryService':
                    this.data[`${service}.${key}`] = this.queryService.getParamValue(this.configuration.dataGroup, 'filter_category')[0]
                    break
            }
        }

        return UtilityService.replaceTemplate(template, this.data)
    }

    isItemDisabled(link: IBreadcrumbLinks) {
        if (link.requiredQueryParam) {
            return !(this.queryService.hasParam(this.configuration.dataGroup, link.requiredQueryParam))
        }

        return false
    }

    private isDataArray(data: any) {
        return (data.data && data.data.length && Array.isArray(data.data))
    }

}
