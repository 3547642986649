<div class="ras-brand-container" *ngIf="configuration.display">
    <h2 class="ras-brand-title" [style]="configuration.titleStyles">{{ configuration.title }}</h2>
    <div class="ras-brand-img-container" [class.ie]="isIE">
        <div class="ras-brand-item" *ngFor="let brand of configuration.content">
            <ng-container *ngTemplateOutlet="!brand.link ? imgContainer : linkContainer; context: { $implicit: brand }"></ng-container>
        </div>
    </div>
</div>

<ng-template #linkContainer let-brand>
    <a [href]="brand.link.url" *ngIf="brand.link.type === 'a'">
        <ng-container *ngTemplateOutlet="imgContainer; context: { $implicit: brand }"></ng-container>
    </a>
    <a [routerLink]="brand.link.url" *ngIf="brand.link.type === 'a-ng'" [queryParams]="brand.link.params">
        <ng-container *ngTemplateOutlet="imgContainer; context: { $implicit: brand }"></ng-container>
    </a>
</ng-template>

<ng-template #imgContainer let-brand>
    <div class="ras-brand-card">
        <div class="img-container">
            <img [src]="brand.image_url" [attr.alt]="'Shop By Brand - ' + brand.title" loading="lazy"/>
        </div>
        <p>{{ brand.title }}</p>
    </div>
</ng-template>
